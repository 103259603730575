@import (reference) "__variables";
@import (reference) "__mixins";
@import (reference) "__common";

// 변수 선언
@wrapper-width: 733px;
@wrapper-height: 500px;
@header-height: 29px;
@header-margin: 6px;
@footer-height: 25px;

body#login {
  background-color: #dcdcdc;
  min-height: @wrapper-height;
  min-width: @wrapper-width;

  .wrapper {
    position: absolute;
    height: @wrapper-height;
    width: @wrapper-width;
    top: 50%;
    left: 50%;
    margin: -(floor(@wrapper-height / 2)) 0 0 -(floor(@wrapper-width / 2));
  }
}

.login-header {
  height: @header-height;
  margin-bottom: @header-margin;

  .site-logo {
    display: inline-block;
    float: left;
    font-size: inherit;
  }

  .remote-support {
    display: inline-block;
    float: right;
    height: @header-height;
    line-height: @header-height - 4px;
    border: 1px solid #b0b0b0;
    background: #e7e7e7 data-uri('images/data-uri/ico-remote-01.png') 10px 5px no-repeat;
    color: #636363;
    padding: 2px 10px 0 35px;

    &:hover {
      background-color: #f9f9f9;
    }
  }
}

.login-box {
  @border: 2px;
  @height: @wrapper-height - @header-height - @header-margin - @footer-height;

  height: @height;
  width: @wrapper-width;
  border: @border solid #0b6ab2;
  background: #fff data-uri('images/data-uri/login-box-bg.png') repeat-y;
  overflow: hidden;

  .login-pic {
    width: 438px;
    height: 436px;
    float: left;

    img {
      max-width: 438px;
      max-height: 436px;
    }
  }

  .login-wrap {
    @form-height: @height - (@border * 2);

    width: 291px;
    margin-left: 438px;
    height: @form-height;
    position: relative;

    .login-form {
      position: absolute;
      width: 100%;
      padding: 11px 13px 10px 16px;
      max-height: @form-height;
      overflow: hidden;
      top: 50%;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);

      fieldset {
        padding: 0;
        margin: 0;
        border: 0;
      }

      legend {
        overflow: hidden;
        display: block;
        height: 35px;
        width: 100%;
        background: transparent url('images/login-title.png') no-repeat;
        text-indent: -9999px;
        margin-bottom: 28px;
        border: 0;
      }

      p {
        margin: 0 0 4px 0;

        &.button {
          margin: 13px 0 7px 0;
        }

        &.save {
          display: inline-block;
          width: 40%;
          float: left;
          padding-top: 4px;
        }

        &.lang {
          display: inline-block;
          width: 115px;
          float: right;
          text-align: right;

          select {
            width: 115px;
          }

          .k-input {
            text-align: left;
          }
        }
      }

      input.dews-ui-textbox {
        height: 39px !important; // dews 텍스트박스는 높이가 고정이라 important 를 추가
      }

      input:not([type='checkbox']), button {
        width: 100%;
      }

      p > label {
        display: none;

        &[for='save'] {
          display: inline;
          font-weight: normal;
          color: #444;
          cursor: pointer;
        }
      }

      #login_btn {
        @bgcolor: #1185de;
        @bgcolor-hover: #0876c9;

        font: 18px "Nanum Barun Gothic";

        height: 42px;
        background-color: @bgcolor !important;
        border-color: @bgcolor !important;

        &:hover {
          background-color: @bgcolor-hover !important;
          border-color: @bgcolor-hover !important;
        }
      }

      input.image-placeholder {
        background: no-repeat 6px 13px;

        &#company {
          background-image: data-uri('images/data-uri/login-company-bg.png');
        }
        &#group {
          background-image: data-uri('images/data-uri/login-group-bg.png');
        }
        &#userid {
          background-image: data-uri('images/data-uri/login-userid-bg.png');
        }
        &#password {
          background-image: data-uri('images/data-uri/login-password-bg.png');
        }
      }

      input::-moz-placeholder {
        color: #939393;
      }
      input:-moz-placeholder {
        color: #939393;
      }
      input::-webkit-input-placeholder {
        color: #939393;
      }
      input:-ms-input-placeholder {
        color: #939393 !important;
      }
    }
  }

  .find-wrap {
    height: 35px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f2f2f2;
    text-align: right;
    padding: 10px 17px 0 17px;

    a {
      color: #444;
    }
  }
}

// IE 9 에서 다국어 환경으로 로드 될 때는 한글을 제외한 모든 언어에서 영어로 placeholder 출력
html:not([lang='ko']) {
  .login-form {
    input.image-placeholder {
      &#company {
        background-image: data-uri('images/data-uri/login-company-bg-en.png') !important;
      }
      &#group {
        background-image: data-uri('images/data-uri/login-group-bg-en.png') !important;
      }
      &#userid {
        background-image: data-uri('images/data-uri/login-userid-bg-en.png') !important;
      }
      &#password {
        background-image: data-uri('images/data-uri/login-password-bg-en.png') !important;
      }
    }
  }
}

.login-footer {
  padding-top: 10px;
  color: #999;
}

.dews-loading-bg {
  background-color: transparent;
}

.dews-ui-loading-container {
  div { background-color: @loading-indicator-color; }
}

input[type="checkbox"].dews-ui-checkbox ~ label[for="save"] {
  visibility: hidden;
}

