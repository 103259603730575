/*!
 * Duzon ERPiU Web Stylesheets for login page v2.0.0
 * Copyright 2015-2016 Duzon BizOn Co.,Ltd
 */
@font-face {
  font-family: 'Nanum Barun Gothic Bold';
  font-weight: 700;
  src: url('../fonts/NanumBarunGothicWebBold.woff') format('woff'), url('../fonts/NanumBarunGothicWebBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nanum Barun Gothic';
  font-weight: normal;
  src: url('../fonts/NanumBarunGothicWeb.woff') format('woff'), url('../fonts/NanumBarunGothicWeb.ttf') format('truetype');
}
@font-face {
  font-family: 'ERPiU';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/erpiu.woff') format('woff'), url('../fonts/erpiu.ttf') format('truetype');
}
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Dotum", "돋움", "Apple SD Gothic Neo", "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, serif;
  font-size: 12px;
}
h1 {
  margin: 0 0;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: underline;
}
a:focus {
  text-decoration: none;
}
label {
  margin-bottom: 0;
  font-weight: normal;
}
th {
  font-weight: normal;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.hide-text {
  overflow: hidden;
  text-indent: -9999px;
}
.clearfix {
  overflow: auto;
  zoom: 1;
}
[class^="ico-"]:before,
[class*=" ico-"]:before {
  font-family: "erpiu";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}
.ico-module:before {
  content: '\e809';
}
.ico-mypage:before {
  content: '\e80b';
}
.ico-my:before {
  content: '\e804';
}
.ico-recent:before {
  content: '\e803';
}
.ico-cross:before {
  content: '\e800';
}
/* '' */
.ico-conversation:before {
  content: '\e801';
}
/* '' */
.ico-config:before {
  content: '\e802';
}
/* '' */
.ico-clock:before {
  content: '\e803';
}
/* '' */
.ico-bookmark:before {
  content: '\e804';
}
/* '' */
.ico-salary:before {
  content: '\e805';
}
/* '' */
.ico-notice:before {
  content: '\e806';
}
/* '' */
.ico-scm:before {
  content: '\e807';
}
/* '' */
.ico-sfa:before {
  content: '\e808';
}
/* '' */
.ico-system:before {
  content: '\e809';
}
/* '' */
.ico-factory:before {
  content: '\e80a';
}
/* '' */
.ico-user:before {
  content: '\e80b';
}
/* '' */
.ico-yetax:before {
  content: '\e80c';
}
/* '' */
.ico-acc:before {
  content: '\e80d';
}
/* '' */
.ico-close-s:before {
  content: '\e80e';
}
/* '' */
.ico-bookmark-fill:before {
  content: '\e80f';
}
/* '' */
.ico-outsourcing:before {
  content: '\e810';
}
/* '' */
.ico-cost:before {
  content: '\e811';
}
/* '' */
.ico-integration:before {
  content: '\e812';
}
/* '' */
.ico-sys:before {
  content: '\e813';
}
/* '' */
.ico-process:before {
  content: '\e814';
}
/* '' */
.ico-premium:before {
  content: '\e815';
}
/* '' */
.ico-linksys:before {
  content: '\e816';
}
/* '' */
.ico-linkedacc:before {
  content: '\e817';
}
/* '' */
.ico-hrm:before {
  content: '\e818';
}
/* '' */
.ico-as:before {
  content: '\e819';
}
/* '' */
.ico-bi:before {
  content: '\e81a';
}
/* '' */
.ico-ec:before {
  content: '\e81b';
}
/* '' */
.ico-eis:before {
  content: '\e81c';
}
/* '' */
.ico-mobile:before {
  content: '\e81d';
}
/* '' */
.ico-monitoring:before {
  content: '\e81e';
}
/* '' */
.ico-standard:before {
  content: '\e81f';
}
/* '' */
.ico-prm:before {
  content: '\e820';
}
/* '' */
.ico-billing:before {
  content: '\e821';
}
/* '' */
.ico-web:before {
  content: '\e822';
}
/* '' */
.ico-project:before {
  content: '\e823';
}
/* '' */
.ico-pims:before {
  content: '\e824';
}
/* '' */
.ico-tm:before {
  content: '\e825';
}
/* '' */
.ico-tax:before {
  content: '\e826';
}
/* '' */
.ico-qa:before {
  content: '\e827';
}
/* '' */
.ico-trade:before {
  content: '\e828';
}
/* '' */
.ico-crm:before {
  content: '\e829';
}
/* '' */
.ico-pms:before {
  content: '\e82a';
}
/* '' */
body#login {
  background-color: #dcdcdc;
  min-height: 500px;
  min-width: 733px;
}
body#login .wrapper {
  position: absolute;
  height: 500px;
  width: 733px;
  top: 50%;
  left: 50%;
  margin: -250px 0 0 -366px;
}
.login-header {
  height: 29px;
  margin-bottom: 6px;
}
.login-header .site-logo {
  display: inline-block;
  float: left;
  font-size: inherit;
}
.login-header .remote-support {
  display: inline-block;
  float: right;
  height: 29px;
  line-height: 25px;
  border: 1px solid #b0b0b0;
  background: #e7e7e7 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAARCAMAAAAIRmf1AAAAolBMVEV6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enr1lFESAAAANnRSTlMAAQIGBwgLDRIUFhobISYsLzA8PT4/QENLWWJkZWZodnp8foOFiY2Oj5Cqra+xuLm6vMTFyczGSnBJAAAAi0lEQVQY043P2xaBYBCG4Vdkv4vsJfsIhXz3f2sOCv0deQ+fNbPWDG6kXPEIIJZRDCA5fHOk1Mj1j63bcClYkCwr9sY0T7oOMM2VpGPLsIYkKVmUkfofjCRFJ6/7+2NGIGkKML5ntsLTYffs5G7VnuGjXr2FNgCldNBvTqD32gJgZdtngHlYo5hl8wZiVxvvBY1bPgAAAABJRU5ErkJggg==") 10px 5px no-repeat;
  color: #636363;
  padding: 2px 10px 0 35px;
}
.login-header .remote-support:hover {
  background-color: #f9f9f9;
}
.login-box {
  height: 440px;
  width: 733px;
  border: 2px solid #0b6ab2;
  background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAtkAAAABCAIAAACE3SWiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjIyMTcyNEUwRDlGOTExRTVCNjIxRkE4OTNGRDI2OTc5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjIyMTcyNEUxRDlGOTExRTVCNjIxRkE4OTNGRDI2OTc5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjIxNzI0REVEOUY5MTFFNUI2MjFGQTg5M0ZEMjY5NzkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjIxNzI0REZEOUY5MTFFNUI2MjFGQTg5M0ZEMjY5NzkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6uQSJoAAAAJElEQVR42mLkztrEMApGwSgYBaMAG/gy1Xc0EEbBKKA1AAgwACn+Av8kpCAEAAAAAElFTkSuQmCC") repeat-y;
  overflow: hidden;
}
.login-box .login-pic {
  width: 438px;
  height: 436px;
  float: left;
}
.login-box .login-pic img {
  max-width: 438px;
  max-height: 436px;
}
.login-box .login-wrap {
  width: 291px;
  margin-left: 438px;
  height: 436px;
  position: relative;
}
.login-box .login-wrap .login-form {
  position: absolute;
  width: 100%;
  padding: 11px 13px 10px 16px;
  max-height: 436px;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.login-box .login-wrap .login-form fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
.login-box .login-wrap .login-form legend {
  overflow: hidden;
  display: block;
  height: 35px;
  width: 100%;
  background: transparent url('images/login-title.png') no-repeat;
  text-indent: -9999px;
  margin-bottom: 28px;
  border: 0;
}
.login-box .login-wrap .login-form p {
  margin: 0 0 4px 0;
}
.login-box .login-wrap .login-form p.button {
  margin: 13px 0 7px 0;
}
.login-box .login-wrap .login-form p.save {
  display: inline-block;
  width: 40%;
  float: left;
  padding-top: 4px;
}
.login-box .login-wrap .login-form p.lang {
  display: inline-block;
  width: 115px;
  float: right;
  text-align: right;
}
.login-box .login-wrap .login-form p.lang select {
  width: 115px;
}
.login-box .login-wrap .login-form p.lang .k-input {
  text-align: left;
}
.login-box .login-wrap .login-form input.dews-ui-textbox {
  height: 39px !important;
}
.login-box .login-wrap .login-form input:not([type='checkbox']),
.login-box .login-wrap .login-form button {
  width: 100%;
}
.login-box .login-wrap .login-form p > label {
  display: none;
}
.login-box .login-wrap .login-form p > label[for='save'] {
  display: inline;
  font-weight: normal;
  color: #444;
  cursor: pointer;
}
.login-box .login-wrap .login-form #login_btn {
  font: 18px "Nanum Barun Gothic";
  height: 42px;
  background-color: #1185de !important;
  border-color: #1185de !important;
}
.login-box .login-wrap .login-form #login_btn:hover {
  background-color: #0876c9 !important;
  border-color: #0876c9 !important;
}
.login-box .login-wrap .login-form input.image-placeholder {
  background: no-repeat 6px 13px;
}
.login-box .login-wrap .login-form input.image-placeholder#company {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAALAgMAAAB9tlchAAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAABgSURBVAjXTY6xDYAwDAQfqowRWqahSJCghj1eTECRASgjT8knuED65vT22dgLAQVDXpATR3A25YbVBionNVv5wXn4mENgBfuObIEUNNuaGCNxdbUZVUnhd6QyexSH74MXbQgpmQbBQkAAAAAASUVORK5CYII=");
}
.login-box .login-wrap .login-form input.image-placeholder#group {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAALAgMAAAB9tlchAAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAABVSURBVAjXTY7BDYAwDAPNJuHbaXg0DNEpLCZgBN6ZEieiFb+cfLYC72hBAJsfGHlR3OKGd1bCXYnAJC0oZ0IFsxPxCHLNT5gRFz7INRbIMaSZ8v+DF1ifKYAMKyCEAAAAAElFTkSuQmCC");
}
.login-box .login-wrap .login-form input.image-placeholder#userid {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAALAgMAAACMvQysAAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAAA1SURBVAjXYwgNaWBgaAwNbWDICmnQYmhcNbWBYeqUhoaGxqmhuFgrcMlGwU1Z2sCAMBnOAgCTiyuCBAVQIgAAAABJRU5ErkJggg==");
}
.login-box .login-wrap .login-form input.image-placeholder#password {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAALAgMAAAB9tlchAAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAABaSURBVAjXYwgNaVjVAAJMKwMYpk5pgADGUBhn1aoGrVUTEDJMkQEMq6ZA9XCGgZUxMDCsgnO0VgH5MNOALCBiTAXrAcooMDCAOEBLgSwgWrVqAYijBbIEZCkAu6kzfzopvvQAAAAASUVORK5CYII=");
}
.login-box .login-wrap .login-form input::-moz-placeholder {
  color: #939393;
}
.login-box .login-wrap .login-form input:-moz-placeholder {
  color: #939393;
}
.login-box .login-wrap .login-form input::-webkit-input-placeholder {
  color: #939393;
}
.login-box .login-wrap .login-form input:-ms-input-placeholder {
  color: #939393 !important;
}
.login-box .find-wrap {
  height: 35px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  text-align: right;
  padding: 10px 17px 0 17px;
}
.login-box .find-wrap a {
  color: #444;
}
html:not([lang='ko']) .login-form input.image-placeholder#company {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAKAgMAAABkuo7cAAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAABdSURBVAjXY4iawgABjKGhIQypKci8qRFcDSsWaDVoMa2MjGCYGqKg0MShoNDQFIbEY0DhaTWFhaUA9cFVAnmpKSCeVgOQFzqFIWoKVwNQjkFLCcQDWgIECiD7okIA8eQhzZMDa74AAAAASUVORK5CYII=") !important;
}
html:not([lang='ko']) .login-form input.image-placeholder#group {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAKAgMAAACoI7Q3AAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAABFSURBVAjXY4hawsDAwBgaypAaAWVMDVHiamicthLI0FBQaJwWxjBthQKEMTUCnZGaAWasZIiaocDVoDUtlCE0BGROWCgAP0kYl8qzPT8AAAAASUVORK5CYII=") !important;
}
html:not([lang='ko']) .login-form input.image-placeholder#userid {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAJAgMAAAAjqbbeAAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAABGSURBVAjXY5iawsDAwBgaGZrAAGWGgplaDCs4p0ZGLQAyG5qYuEIjU0GiDUxMnFCmFtMqGJOhiQHGBKqFMLOmgEwIBZoAAC7eHCIoxGQXAAAAAElFTkSuQmCC") !important;
}
html:not([lang='ko']) .login-form input.image-placeholder#password {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAAJAgMAAADrxVwIAAAACVBMVEWTk5P///+Tk5Mzzj+3AAAAAnRSTlMAAHaTzTgAAABWSURBVAjXXcvBCYBADETRYU6DVQSPW48lWEjIyQLcu8dglWZhQXFOefCD+8Q7oe8/NiQvM0tFFj0oma1cJNRvVDSoYt+aV6wjJt0JfTgO1mZsFZNIMR8QCRjeFbnyMAAAAABJRU5ErkJggg==") !important;
}
.login-footer {
  padding-top: 10px;
  color: #999;
}
.dews-loading-bg {
  background-color: transparent;
}
.dews-ui-loading-container div {
  background-color: #85c3f3;
}
input[type="checkbox"].dews-ui-checkbox ~ label[for="save"] {
  visibility: hidden;
}
