@font-face {
  font-family: 'Nanum Barun Gothic Bold';
  font-weight: 700;
  src: url('../fonts/NanumBarunGothicWebBold.woff') format('woff'),
       url('../fonts/NanumBarunGothicWebBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nanum Barun Gothic';
  font-weight: normal;
  src: url('../fonts/NanumBarunGothicWeb.woff') format('woff'),
       url('../fonts/NanumBarunGothicWeb.ttf') format('truetype');
}
@font-face {
  font-family: 'ERPiU';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/erpiu.woff') format('woff'),
       url('../fonts/erpiu.ttf') format('truetype');
}

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Dotum", "돋움", "Apple SD Gothic Neo", "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, serif;
  font-size: 12px;
}

h1 {
  margin: 0 0;
}

a {
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: none;
  }
}

label {
  margin-bottom: 0;
  font-weight: normal;
}

th {
  font-weight: normal;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hide-text {
  overflow: hidden;
  text-indent: -9999px;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

[class^="ico-"]:before, [class*=" ico-"]:before {
  font-family: "erpiu";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.ico-module:before {
  content: '\e809';
}  // ico-system
.ico-mypage:before {
  content: '\e80b';
} // ico-user
.ico-my:before {
  content: '\e804';
} // ico-bookmark
.ico-recent:before {
  content: '\e803';
} // ico-clock

.ico-cross:before { content: '\e800'; } /* '' */
.ico-conversation:before { content: '\e801'; } /* '' */
.ico-config:before { content: '\e802'; } /* '' */
.ico-clock:before { content: '\e803'; } /* '' */
.ico-bookmark:before { content: '\e804'; } /* '' */
.ico-salary:before { content: '\e805'; } /* '' */
.ico-notice:before { content: '\e806'; } /* '' */
.ico-scm:before { content: '\e807'; } /* '' */
.ico-sfa:before { content: '\e808'; } /* '' */
.ico-system:before { content: '\e809'; } /* '' */
.ico-factory:before { content: '\e80a'; } /* '' */
.ico-user:before { content: '\e80b'; } /* '' */
.ico-yetax:before { content: '\e80c'; } /* '' */
.ico-acc:before { content: '\e80d'; } /* '' */
.ico-close-s:before { content: '\e80e'; } /* '' */
.ico-bookmark-fill:before { content: '\e80f'; } /* '' */
.ico-outsourcing:before { content: '\e810'; } /* '' */
.ico-cost:before { content: '\e811'; } /* '' */
.ico-integration:before { content: '\e812'; } /* '' */
.ico-sys:before { content: '\e813'; } /* '' */
.ico-process:before { content: '\e814'; } /* '' */
.ico-premium:before { content: '\e815'; } /* '' */
.ico-linksys:before { content: '\e816'; } /* '' */
.ico-linkedacc:before { content: '\e817'; } /* '' */
.ico-hrm:before { content: '\e818'; } /* '' */
.ico-as:before { content: '\e819'; } /* '' */
.ico-bi:before { content: '\e81a'; } /* '' */
.ico-ec:before { content: '\e81b'; } /* '' */
.ico-eis:before { content: '\e81c'; } /* '' */
.ico-mobile:before { content: '\e81d'; } /* '' */
.ico-monitoring:before { content: '\e81e'; } /* '' */
.ico-standard:before { content: '\e81f'; } /* '' */
.ico-prm:before { content: '\e820'; } /* '' */
.ico-billing:before { content: '\e821'; } /* '' */
.ico-web:before { content: '\e822'; } /* '' */
.ico-project:before { content: '\e823'; } /* '' */
.ico-pims:before { content: '\e824'; } /* '' */
.ico-tm:before { content: '\e825'; } /* '' */
.ico-tax:before { content: '\e826'; } /* '' */
.ico-qa:before { content: '\e827'; } /* '' */
.ico-trade:before { content: '\e828'; } /* '' */
.ico-crm:before { content: '\e829'; } /* '' */
.ico-pms:before { content: '\e82a'; } /* '' */
